import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {getReceived} from "../../store/received/actions";
import ReceivedList from "./List/ReceivedList";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Paging from "../../components/Pagination/OldPagination";
import {Space} from "antd";
import {Button, Card, CardBody, Col, Row} from "reactstrap";

const Receiveds = ({receivedList, getReceived}) => {

    const [state, setState] = useState({page: 1})
    const setPage = (currentPage) => {
        if(currentPage !== state.page) {
            setState({page: currentPage});
            getReceived( {page: currentPage} );
        }
    };
    const [page] = useState(1)

    useEffect(() => {
        getReceived({page: page})
    }, []);

    let receipts = {}
    if( receivedList )
        receipts = receivedList.receipts

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Received" breadcrumbItem="Received"/>

                    <Row>
                        <Col>
                            <Card>
                                <div className="d-inline-flex ant-row-space-between align-items-center">
                                    <Paging setPage={(page) => setPage(page)} currentPage={page} pages={receivedList?.pages}/>
                                    {/*<Space direction="horizontal" className="mr-4">*/}
                                    {/*    <Button type={'button'} className='btn btn-info waves-effect waves-light search-btn'*/}
                                    {/*            onClick={() => this.setFilterModalOpen(!filterModalOpen)}><i*/}
                                    {/*        className="bx bx-filter-alt align-middle"></i></Button>*/}
                                    {/*    {(search.text || Object.keys(search.tagsFilters).length > 0) &&*/}
                                    {/*        <Button type={'button'} className='btn btn-dark waves-effect waves-light search-btn'*/}
                                    {/*                onClick={this.refreshPage}><i*/}
                                    {/*            className='bx bx-revision align-middle'></i></Button>}*/}
                                    {/*</Space>*/}
                                </div>
                                <CardBody>
                                    <ReceivedList receivedList={receivedList} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};


const mapStateToProps = ({Received}) => {
    return Received;
}

export default connect(mapStateToProps, {getReceived})(Receiveds);