import ApiService from "../../helpers/ApiHelper";

class ReceivedService {

    static getReceiveds(payload) {
        return ApiService.request(
            "/api/v1/received/all",
            "post",
            payload
        )
    }

}

export default ReceivedService;