import React, {Component} from "react";
import {Table, Thead, Tbody, Tr, Th, Td} from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import ClinicEdit from "./ClinicEdit";
import axios from "axios";
import baseURL from "../../../config";
import fileDownload from "js-file-download";
import {ProfessionalActionTypes} from "../../../store/professionals/actionTypes";
import {Button} from "reactstrap";
import RemoveList from "../../../components/RemoveList/RemoveList";
import QRCodeView from "../../../components/QRCode/QRCodeView";
import ServiceList from "../../../components/ServiceListUpdate/ServiceList";
import {connect} from "react-redux";
import {getTranslation} from "../../../store/translations/actions";
import WorkSchedule from "../../../components/WorkShedule/WorkSchedule";
import {createProfessionalMetaHeadData} from "../../../store/metaHead/actions";
import {approveProfessional} from "../../../store/professionals/actions";
import ProfessionalEdit from "../../Professionals/List/ProfessionalEdit";
import dayjs from "dayjs";
import Ratings from "../../../components/Ratings/Ratings";

class ClinicList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditModalOpen: false,
      isRemoveModalOpen: false,
      isServiceEditModalOpen: false,
      isWorkScheduleModalOpen: false,
      isViewRatingsOpen: false,
      removeClinic: null,
      defaultValues: {metadata: {services: [{serviceName: '', price: 0, duration: 20}]}},
      editClinic: null,
      clinicListData: [],
      title: '',
      subTitle: '',
      status: ''
    };
    this.onEditOpen = this.onEditOpen.bind(this);
    this.onEditSubmit = this.onEditSubmit.bind(this);
    this.onRemoveOpen = this.onRemoveOpen.bind(this);
    this.onRemoveSubmit = this.onRemoveSubmit.bind(this);
    this.specializationFileDownload =
      this.specializationFileDownload.bind(this);
    this.onDeclineAccount = this.onDeclineAccount.bind(this);
  }

  componentDidMount() {
    this.props.getTranslation({type: 'normal'})
  }

  onEditOpen(clinic) {
    if (clinic?.metadata?.dateOfCourtRegistration) {
      clinic.metadata.dateOfCourtRegistration = dayjs(clinic?.metadata?.dateOfCourtRegistration)
    }
    this.setState((prevState) => ({
      isEditModalOpen: !prevState.isEditModalOpen,
      editClinic: clinic,
    }));

    if(this.state.isEditModalOpen) {
      window.location.reload()
    }

  }

  specializationFileDownload(fileUrl, nameFile) {
    const fileName = nameFile;
    const fileExtention = fileUrl.substr(fileUrl.lastIndexOf(".") + 1);
    axios
      .get(fileUrl, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, `${fileName}.${fileExtention}`);
      });
  }

  onDeclineAccount(declineAccount) {
    this.props.approveProfessional(declineAccount)

  }

  onServiceOpen = (professional) => {
    let defaultServices = {metadata: {services: [{serviceName: '', price: 0, duration: 20}]}}
    if (professional && professional?.metadata && professional?.metadata.services && professional?.metadata.services.length) {
      defaultServices = {metadata: {services: professional?.metadata.services}}
    }
    this.setState({
      isServiceEditModalOpen: !this.state.isServiceEditModalOpen,
      editClinic: professional,
      defaultValues: defaultServices
    });
  }

  onRemoveOpen(professional, title, subTitle, status) {
    console.log(professional, this.state.isRemoveModalOpen)
    this.setState((prevState) => ({
      isRemoveModalOpen: !prevState.isRemoveModalOpen,
      removeClinic: professional,
      title,
      subTitle,
      status
    }));
  }

  onRemoveSubmit(clinic) {
    this.props.onRemoveItem(clinic);
    window.location.reload()

  }

  onApproveSubmit = (professional) => {
    this.props.onApproveItem(professional);
    window.location.reload()

  }
  onRatingsOpen = (professional) => {
    this.setState({
      isViewRatingsOpen: !this.state.isViewRatingsOpen,
      editClinic: professional,
    });
  }
  onEditSubmit(clinic) {
    this.props.onEditItem(clinic);
    window.location.reload()
  }

  getClinicsList(clinicListData) {
    if (clinicListData) {
      const clinicListView = [];

      clinicListData.map((user, i) => {
        if (user.role === "CLINIC" || user.role === "TRAINER") {
          const userApproved = this.userApproved(user.approved);
          const userVerified = this.userApprovedStatus(user.verified);

          clinicListView.push(
            <Tr key={user._id}>
              <Th>{user.name}{' '}{user.surname}<br/><br/>{user.telephone}</Th>
              <Th>{user.email}
                <br/>
                <br/>{user.metadata && user.metadata?.QRMetadata &&
                  <QRCodeView qrcode={user.metadata?.QRMetadata} i={i}/>}</Th>
            <Th>
              {user?.metadata?.fee && user?.metadata.feeType === "SUBSCRIPTION" && (
                <React.Fragment>
                  <br/>
                  <b>{`${user?.metadata.feeType} ${user?.metadata.fee.feeAmount} ${user?.metadata.fee.currency}`}</b>
                  <br />
                  {`${dayjs(user?.metadata.fee.validFrom).format("DD.MM.YYYY")} - ${dayjs(user?.metadata.fee.validTo).format("DD.MM.YYYY")}`}
                </React.Fragment>
              )}              
            </Th>
              <Th>{user.metadata?.businessName}</Th>
              <Th>{user.metadata?.companyName}</Th>
              <Td>
                {!user.active ? user?.step : 6} / 6
              </Td>
              <Td>
                {user?.createdAt ? <div>
                  Create:{dayjs(user.createdAt).format('DD.MM.YYYY')}
                </div> : ''}
                {user?.updatedAt ? <div>
                  Update:{dayjs(user.updatedAt).format('DD.MM.YYYY')}
                </div> : ''}
                {user?.approvedDate ? <div>
                  Approved:{dayjs(user.approvedDate).format('DD.MM.YYYY')}
                </div> : ''}
              </Td>
              <Th>{userApproved}
                {/*<div>*/}
                {/*  <Button*/}
                {/*    className="btn waves-effect waves-light"*/}
                {/*    onClick={() => {*/}
                {/*      user.approved = 'Pending'*/}
                {/*      this.onRemoveOpen(user,*/}
                {/*        'Do you want to \'Pending\' this Clinic?',*/}
                {/*        'Disable Clinic',*/}
                {/*        ProfessionalActionTypes.DISABLE);*/}
                {/*    }}*/}
                {/*  ><i className="bx bxs-down-arrow font-size-16 align-middle"></i></Button>*/}
                {/*</div>*/}
              </Th>
              <Th>{userVerified}</Th>
              <Td>
                <div className="button-items d-flex">
                  <button
                      onClick={() => {
                        this.onRatingsOpen(user);
                      }}
                      className="btn-primary btn waves-effect waves-light">
                    <span style={{fontSize: '12px'}}>{Math.round(Number(user?.rating) / 0.5) * 0.5}</span>
                    <i className="bx bxs-star font-size-12 align-middle"
                       style={{color: 'yellow', padding: '2px', marginBottom: '2px'}}></i>
                  </button>
                  <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light"
                      onClick={() => {
                        this.onEditOpen(user);
                      }}
                  >
                    <i className="bx bxs-edit font-size-16 align-middle"></i>
                  </button>
                  <button
                      type="button"
                      className="btn btn-warning waves-effect waves-light"
                      onClick={() => {
                        this.onServiceOpen(user);
                      }}
                  >
                    <i className='bx bx-calendar font-size-16 align-middle'></i>
                  </button>
                  <button
                      type="button"
                      className="btn btn-warning waves-effect waves-light"
                      onClick={() => {
                        this.onWorkScheduleOpen(user);
                      }}
                  >
                    <i className='bx bx-time font-size-16 align-middle'></i>
                  </button>
                  {/*<button*/}
                  {/*  type="button"*/}
                  {/*  className="btn btn-danger waves-effect waves-light"*/}
                  {/*  onClick={() => {*/}
                  {/*    this.onRemoveOpen(user,*/}
                  {/*      'Do you want to remove this Clinic?',*/}
                  {/*      'Remove Clinic',*/}
                  {/*      ProfessionalActionTypes.REMOVE);*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  <i className="bx bx-trash-alt font-size-16 align-middle"></i>*/}
                  {/*</button>*/}
                </div>
              </Td>
            </Tr>
          );
        }
      });

      return clinicListView;
    } else {
      return null;
    }
  }

  userApproved(status) {
    let value;
    switch (status) {
      case "Yes":
        value = "Yes";
        break;
      case "No":
        value = "No";
        break;
      default:
        value = "Pending";
    }
    return value;
  }

  userApprovedStatus(status) {
    let value;
    switch (status) {
      case true:
        value = "Yes";
        break;
      case false:
        value = "No";
        break;
      default:
        value = "Pending";
    }
    return value;
  }

  onWorkScheduleOpen = (professional) => {
    this.setState({
      isWorkScheduleModalOpen: !this.state.isWorkScheduleModalOpen,
      editClinic: professional
    })
  }

  render() {
    const {
      isEditModalOpen,
      isRemoveModalOpen,
      isServiceEditModalOpen,
      isWorkScheduleModalOpen,
      isViewRatingsOpen,
      defaultValues,
      removeClinic,
      editClinic,
      title,
      subTitle,
      status
    } =
      this.state;
    const {list, createProfessionalMetaHeadData} = this.props
    return (
      <div className="table-rep-plugin">
        <div className="table-responsive mb-0" data-pattern="priority-columns">
          <Table
            id="tech-companies-1"
            className="table table-striped table-bordered"
          >
            <Thead>
              <Tr>
                <Th data-priority="1">Name </Th>
                <Th data-priority="3">Email</Th>
                <Th data-priority="3">Subscription</Th>
                <Th data-priority="3">Business name</Th>
                <Th data-priority="3">Company name</Th>
                <Th data-priority="3">Profile Step</Th>
                <Th data-priority="3">Info</Th>
                <Th data-priority="3">Approved</Th>
                <Th data-priority="3">Active</Th>
                <Th data-priority="3">Actions</Th>
              </Tr>
            </Thead>
            <Tbody>{this.getClinicsList(list?.users)}</Tbody>
          </Table>
        </div>
        <RemoveList
          data={removeClinic}
          onSubmit={status === ProfessionalActionTypes.DISABLE ? this.onApproveSubmit : this.onRemoveSubmit}
          isRemoveModalOpen={isRemoveModalOpen}
          onCancel={this.onRemoveOpen}
          title={title}
          subTitle={subTitle}
          status={status}
        />
        {editClinic && (
          <ProfessionalEdit
            admin={this.props?.user}
            data={editClinic}
            onSubmit={this.onApproveSubmit}
            onUpdate={this.onEditSubmit}
            onDeclineAccount={this.onDeclineAccount}
            createProfessionalMetaHeadData={createProfessionalMetaHeadData}
            isEditModalOpen={isEditModalOpen}
            onCancel={this.onEditOpen}
            removeOrder={this.onRemoveOpen}
          />
        )}
        {isWorkScheduleModalOpen && <WorkSchedule professional={editClinic} isWorkScheduleModalOpen={isWorkScheduleModalOpen}
                       onCancel={this.onWorkScheduleOpen}/>}
        {editClinic && <ServiceList
          user={editClinic}
          defaultValues={defaultValues}
          setServiceList={this.setState.bind(this)}
          isServiceEditModalOpen={isServiceEditModalOpen}
          onCancel={this.onServiceOpen}
          onUpdate={this.onEditSubmit}/>}
        {editClinic && <Ratings
            user={editClinic}
            isOpen={isViewRatingsOpen}
            onCancel={this.onRatingsOpen}
            onUpdate={this.onRatingsOpen}/>}

      </div>
    );
  }
}

export default connect(({Login}) => {
  return Login
}, {getTranslation, approveProfessional, createProfessionalMetaHeadData})(ClinicList);
