import { ReceivedActionTypes } from "./actionTypes";


export const getReceived = (payload) => {
  return {
    type: ReceivedActionTypes.RECEIVED_LIST_GET,
    payload: payload,
  };
};

export const getReceivedSuccessful = (payload) => {
  return {
    type: ReceivedActionTypes.RECEIVED_LIST_GET_SUCCESSFUL,
    payload: payload,
  };
};

export const getReceivedError = (payload) => {
  return {
    type: ReceivedActionTypes.RECEIVED_LIST_GET_ERROR,
    payload,
  };
};
