import React from 'react';
import {Table, Tbody, Td, Th, Thead, Tr} from "react-super-responsive-table";
import dayjs from "dayjs";

const ReceivedList = ({receivedList}) => {

    const getReceivedList = (list) => {
        let rcpts = {}
        if( list )
            rcpts = list.receipts

      if(rcpts && rcpts.length) {
          return rcpts.map((item, index) => (
              <Tr key={index}>
                  <Td>{item.receivedNumber}</Td>
                  <Td>{item.bookingId.meetingType}</Td>
                  <Td>
                      <div className={'text-danger'}>{item.receivedType === 'refund' ? `${item.amount}` : item.bookingId.price}</div></Td>
                  <Td>{item?.bookingId?.professionalID?.name} {item?.bookingId?.professionalID?.surname}</Td>
                  <Td>{item?.bookingId?.parentID?.name} {item?.bookingId?.parentID?.surname}</Td>
                  <Td className="text-capitalize">{item.receivedType}</Td>
                  <Td>{item?.pdf ? <div>
                      <a
                              className="btn btn-primary mt-1"
                              download={`${dayjs(item.createdAt).format('DD-MM-YYYY')}.pdf`}
                              href={`data:application/pdf;base64,${item.pdf}`}
                          >{`${dayjs(item.createdAt).format('DD-MM-YYYY')}`}</a>
                  </div> : null}</Td>
                  <Td>{dayjs(item.createdAt).format('DD-MM-YYYY HH:mm')}</Td>
              </Tr>
          ));
      }
    }

    return (
        <div className="table-rep-plugin">
            <div className="table-responsive mb-0 text-lg-center" data-pattern="priority-columns">
                <Table
                    id="tech-companies-1"
                    className="table table-striped table-bordered"
                >
                    <Thead>
                        <Tr>
                            <Th data-priority="3">Receipt №</Th>
                            <Th data-priority="1">Meeting Type</Th>
                            <Th data-priority="3">Amount</Th>
                            <Th data-priority="3">Professional Name</Th>
                            <Th data-priority="3">Parent Name</Th>
                            <Th data-priority="3">Receipt Type</Th>
                            <Th data-priority="3">Pdf</Th>
                            <Th data-priority="3">Created At</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {getReceivedList(receivedList)}
                    </Tbody>
                </Table>
            </div>
        </div>
    );
};

export default ReceivedList;